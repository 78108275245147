import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = ({ data }) => (
  <Layout>
    <SEO title="Shine, Glow, Grow Brilliant" />

    <h1>Welcome to Candeo Design</h1>
    <p>
      Candeo Design is a landscape design company dedicated and passionate about
      creating stunning, user friendly and unique gardens and outdoor spaces.
    </p>
    <p>
      All designs are based on strong horticultural practices as well as
      creative use of quality and interesting products throughout the garden.
      Strong colour and texture is a common theme throughout Candeo Design
      gardens creating beautiful visual effects.
    </p>
    <p>
      These design theories are the corner stone of Candeo Design and will help
      make your new garden "shine, glow, grow brilliant".
    </p>
  </Layout>
)

export default Content
